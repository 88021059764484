import { useRef, useEffect } from "react";
// import one from "../../../assets/photo1.jpeg";
// import two from "../../../assets/Account.jpg";
// // import three from "../../../assets/Order.jpg";
// import four from "../../../assets/Dispatch.jpg";
import Footer from "../Footer";
import Whatsapp from "../../Whatsapp";
import "./TimelineCard.css"; // Import the CSS file

export default function TimelineCard() {
  const timelineRefs = useRef([]);

  useEffect(() => {
    const observerOptions = {
      root: null, // viewport
      rootMargin: "0px",
      threshold: [0.1], // Trigger when 10% of the item is visible
    };

    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
        } else {
          entry.target.classList.remove("visible");
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    timelineRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      if (timelineRefs.current) {
        timelineRefs.current.forEach((ref) => {
          if (ref) observer.unobserve(ref);
        });
      }
    };
  }, []);

  return (
    <>
      <div style={{ paddingTop: "5rem" }}>
        <div className="card-wrapper">
          <div className="card-content">
            <div className="header-info">
              <h1>How We Work</h1>
              <p>Here's a timeline of our key milestones.</p>
            </div>
            <div className="timeline-wrapper">
              {[
                {
                  title: "Pick Products",
                  description:
                    "Browse a huge selection of handicraft products from our gallery.",
                  image: "https://d1gyw402jddya1.cloudfront.net/photo1.jpeg",
                },
                {
                  title: "Account Opening & Listing",
                  description:
                    "Our managers will open accounts on the desired ecommerce platform & list the products selected by you.",
                  image: "https://d1gyw402jddya1.cloudfront.net/Account.jpg",
                  reverse: true,
                },
                {
                  title: "You Receive Order",
                  description:
                    "Once product listing is done, you will start receiving orders",
                  image: "https://d1gyw402jddya1.cloudfront.net/Order.jpg",
                },
                {
                  title: "Dispatching",
                  description:
                    "Orders will be directly dispatched from our warehouse",
                  image: "https://d1gyw402jddya1.cloudfront.net/Dispatch.jpg",
                  reverse: true,
                },
              ].map((item, index) => (
                <div
                  key={index}
                  ref={(el) => (timelineRefs.current[index] = el)}
                  className={`timeline-item ${item.reverse ? "reverse" : ""}`}
                >
                  {item.reverse ? (
                    <>
                      <div className="image-content">
                        <img src={item.image} alt={`${item.title} Milestone`} />
                      </div>
                      <div className="text-content">
                        <h2>{item.title}</h2>
                        <p>{item.description}</p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="text-content">
                        <h2>{item.title}</h2>
                        <p>{item.description}</p>
                      </div>
                      <div className="image-content">
                        <img src={item.image} alt={`${item.title} Milestone`} />
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
\      <Whatsapp />
    </>
  );
}
