import React from "react";
import styled from "styled-components";
import { Card, Typography } from "antd";

import Footer from "../Footer";
import Whatsapp from "../../Whatsapp";

const { Title, Paragraph } = Typography;

export default function AboutUs() {
  return (
    <>
    <AboutUsWrapper style={{paddingTop:"5rem"}}>
      <StyledCard bordered={true}>
        <Title level={2} className="font40 extraBold">About Us</Title>
        <Paragraph className="font16">
          We are a team of dedicated professionals committed to delivering high-quality products and services.
          Our mission is to provide innovative solutions that meet the needs of our clients.
          With years of experience in the industry, we strive for excellence in everything we do.
        </Paragraph>
        <Paragraph className="font16">
          Our values are rooted in integrity, transparency, and customer satisfaction.
          We believe in building long-term relationships with our clients and partners, based on trust and mutual respect.
          Join us on our journey to success.
        </Paragraph>
      </StyledCard>
    </AboutUsWrapper>
    <Footer/>
    <Whatsapp/>
    </>
  );
}

const AboutUsWrapper = styled.div`
  padding: 50px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f2f5;

  @media (max-width: 860px) {
    padding: 30px 10px;
  }
`;

const StyledCard = styled(Card)`
  width: 100%;
  max-width: 800px;
  border: 2px solid orange;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  .ant-typography {
    text-align: center;
  }

  @media (max-width: 860px) {
    width: 90%;
  }
`;
