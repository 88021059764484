import React from "react";
import { Layout, Row, Col, Typography, Space, Button } from "antd";
import { InstagramOutlined, FacebookOutlined, MailOutlined } from "@ant-design/icons";
import { Link } from "react-scroll";
// Assets
// import Logo from "../../assets/logo.png";

const { Footer } = Layout;
const { Title, Text } = Typography;

export default function Contact() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Footer style={{ backgroundColor: "#ffffff", padding: "40px 0" }}>
      <div className="container">
        <Row align="middle" justify="space-between" gutter={[16, 32]}>
          <Col xs={24} sm={12} md={8}>
            <Space size="middle">
              <Link to="home" smooth={true} offset={-80} className="pointer">
              <img src="https://d1gyw402jddya1.cloudfront.net/logo.png" width="200px" alt="Logo" />
              </Link>
            </Space>
          </Col>

          <Col xs={24} sm={12} md={8}>
            <Title level={4} style={{ color: "#333", textAlign: "center" }}>Connect with Us</Title>
            <Space direction="vertical" size="middle" style={{ width: "100%", textAlign: "center" }}>
              <Button
                type="link"
                href="https://instagram.com"
                target="_blank"
                icon={<InstagramOutlined />}
                style={{ color: "#ff7f50", fontSize: "16px" }}
              >
                Instagram
              </Button>
              <Button
                type="link"
                href="https://facebook.com"
                target="_blank"
                icon={<FacebookOutlined />}
                style={{ color: "#ff7f50", fontSize: "16px" }}
              >
                Facebook
              </Button>
              <Button
                type="link"
                icon={<MailOutlined />}
                style={{ color: "#ff7f50", fontSize: "16px" }}
              >
                Contact Us
              </Button>
            </Space>
          </Col>

          <Col xs={24} sm={24} md={8}>
            <Title level={4} style={{ color: "#333", textAlign: "center" }}>Newsletter</Title>
            <Space direction="vertical" style={{ width: "100%", textAlign: "center" }}>
              <Text style={{ fontSize: "16px" }}>Subscribe to our newsletter</Text>
              <div style={{ display: "flex", marginTop: "8px", justifyContent: "center" }}>
                <input
                  type="email"
                  placeholder="Enter your email"
                  style={{
                    padding: "10px",
                    fontSize: "16px",
                    border: "1px solid #ddd",
                    borderRadius: "4px 0 0 4px",
                    outline: "none",
                    width: "70%",
                    maxWidth: "300px"
                  }}
                />
                <button
                  style={{
                    padding: "10px 20px",
                    fontSize: "16px",
                    backgroundColor: "#ff7f50",
                    border: "none",
                    borderRadius: "0 4px 4px 0",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                >
                  Subscribe
                </button>
              </div>
            </Space>
          </Col>
        </Row>

        <Row justify="center" style={{ marginTop: "20px", textAlign: "center" }}>
          <Text type="secondary" style={{ fontSize: "14px" }}>
            © {getCurrentYear()} Saumic Craft. All Rights Reserved.
          </Text>
        </Row>
      </div>
    </Footer>
  );
}
