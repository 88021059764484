import React from "react";
import { Helmet } from "react-helmet";
// Screens
import Landing from "./screens/Landing.jsx";
import { Routes, Route } from "react-router-dom";
import How from "./components/Sections/How/How.jsx";
import TopNavbar from "./components/Nav/TopNavbar.jsx";
import About from "./components/Sections/About/About.jsx";
import Contact from "./components/Sections/Contact/Contact.jsx";

export default function App() {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
      </Helmet>
      <TopNavbar/>
      <Routes>
        <Route path='/' element={<Landing/>} />
        <Route path='/howwework' element={<How/>} />
        <Route path='/about' element={<About/>} />
        <Route path='/contact' element={<Contact/>} />
      </Routes>
    </>
  );
}

